/**
 * axios setup to use mock service
 */

import axios from "axios";

const axiosServices = axios.create();

// Interceptor pour ajouter le header d'autorisation
axiosServices.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {};
    if (
      config.url?.includes("aerial/") &&
      config?.data?.agenceInfo?.gie &&
      config?.data?.agenceInfo?.gie !== "Tourcom"
    ) {
      config.url = config.url.replace("aerial", "aggregator");
    }
    const nuxtApp = useNuxtApp();
    // Vérification de l'expiration du token
    if (
      nuxtApp.$keycloak.getToken() &&
      nuxtApp.$keycloak.isValidateToken(5400)
    ) {
      nuxtApp.$keycloak.updateToken();
    }

    // Votre token, vous pourriez le récupérer depuis un store, localstorage, etc.
    const token = localStorage.getItem("keycloak-token");
    // Si le token existe, ajoutez-le au header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      // config.headers.toto = "SALUT";
    }

    // Retournez la configuration modifiée
    return config;
  },
  (error) => {
    // Si une erreur se produit avant que la requête ne soit effectuée, vous pouvez la gérer ici.
    return Promise.reject(error);
  }
);

// Interceptor pour gérer les réponses
axiosServices.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject((error.response && error.response.data) || "Wrong Services")
);

// Attention, vous pouvez utiliser directement axios
export default axiosServices;
